<script setup>
import stores from "@/Stores/stores.js";
import {storeToRefs} from "pinia";

const {configStore, objectStore} = stores();
const {activeObject, activeClient} = storeToRefs(configStore);
const {objectData} = storeToRefs(objectStore);


const props = defineProps({
    filename: {
        type: String,
       required: true
    }
})
</script>

<template>
 <div class="col-span-12 md:col-span-4">
            <div class="relative">
                <img class="rounded w-full"
                     :src="`clients/${activeClient}/objects/${activeObject}/${filename}`"
                     alt="Art Image">
            </div>
        </div>
</template>
